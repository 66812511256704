import React from "react";
import "./Loading.css";

function Loading() {
	return (
		<div className='loading'>
			<span className='loader'></span>
		</div>
	);
}

export default Loading;
