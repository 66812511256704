import React from "react";
import SetDomainType from "./SetDomainType";

const DomainName = ({ domain, settingErr, setDomain }) => {
	return (
		<>
			<div className='row d-flex justify-content-center align-items-center'>
				<div className='col-lg-8 col-12'>
					<div className='store_email'>
						<label htmlFor='domain' className='setting_label gap-0 mb-1'>
							الدومين
							<span className='important-hint ps-1'>*</span>
							<span className='tax-text ps-1'>(رابط المتجر)</span>
						</label>
					</div>
				</div>

				{/* has domain name */}
				<SetDomainType
					domain={domain}
					setDomain={setDomain}
					settingErr={settingErr}
					hint={`قم بادخال اسم الدومين الخاص بك ،مع مراعاة  أن يكون الدومين حروف انجليزية وأرقام فقط`}
				/>
			</div>
		</>
	);
};

export default DomainName;
